import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Context
import { PageSetup } from "../components/context/page-context";
import { useDraftProductsContext } from "../components/context/draft-products";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Containers
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Components
import { SingleProduct } from "../components/product/single-product";
import { MobileFilters } from "../components/filter/mobile-filters";

// Utils
import { getMetafield } from "../components/utils/get-metafield";
import { dynamicSort } from "../components/utils/dynamic-sort";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 50px 0;

  @media (max-width: 960px) {
    margin: 30px 0 75px 0;
  }

  & .banner-container {
    overflow: hidden;
    margin: 0 0 40px 0;

    & .banner {
      position: relative;
      width: 100%;
      height: 100%;

      & .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }

      &.no-image {
        /* max-height: 515px; */
        background-color: #f5eadf;

        & .aspect-ratio-image-container {
          /* max-height: 515px; */
        }
      }

      & h1 {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        padding: 0 10px;

        z-index: 10;

        /* font-size: 48px;
        line-height: 65px; */

        font-size: 38px;
        line-height: 52px;

        text-transform: uppercase;
        text-align: center;

        color: ${props => props.color};

        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 20px;
        }
      }
    }
  }

  /* & .banner-container {
    overflow: hidden;
    margin: 0 0 40px 0;

    & .banner {
      max-height: 515px;
      background-color: #f5eadf;



      & .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }

      & h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        z-index: 10;

        font-size: 48px;
        line-height: 65px;

        text-transform: uppercase;

        color: #0b646f;

        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  } */

  & .breadcrumbs {
    padding: 0 50px;
    margin: 0 0 10px 0;

    & p {
      font-size: 12px;
      line-height: 28px;

      color: #0b646f;

      margin: 0;
    }

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 10px;
      margin: 0;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .products-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 100px;

    padding: 0 50px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }
  }

  & .empty-products-container {
    padding: 50px;

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    & h1 {
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      color: #0b646f;
    }
  }
`;

const Collection = ({ data, location }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const [hasData, setHasData] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  // Draft Products
  const { draftProducts } = useDraftProductsContext();

  useEffect(() => {
    if (data.shopifyCollection === null) {
      setHasData(false);
    } else {
      const allArtists = Array.from(
        new Set(
          data.shopifyCollection.products
            .map(product => getMetafield(product, "artist_name"))
            .filter(artist => artist !== null)
            .map(artist => artist.trim())
        )
      ).sort();

      setPageSetup({
        pageTitle: ``,
        pageType: `collections`,
        filterArtists: allArtists,
        selectedArtists: [],
        sortOption: null,
        priceOption: null,
        artistOption: [],
        applyFilter: false,
      });
    }
  }, [data]);

  if (data.shopifyCollection === null) return null;

  const filteredProducts = data.shopifyCollection.products
    .filter(product => draftProducts.indexOf(product.shopifyId) === -1)
    .filter(product => {
      if (pageSetup.priceOption !== null && pageSetup.applyFilter === true) {
        if (pageSetup.priceOption.direction === `greater`) {
          if (
            product.priceRangeV2.minVariantPrice.amount >=
            pageSetup.priceOption.price
          ) {
            return product;
          }
        } else {
          if (
            product.priceRangeV2.minVariantPrice.amount <=
            pageSetup.priceOption.price
          ) {
            return product;
          }
        }
      } else {
        return product;
      }
    })
    .filter(product => {
      if (
        pageSetup.artistOption.length >= 1 &&
        pageSetup.applyFilter === true
      ) {
        if (getMetafield(product, "artist_name") !== null) {
          if (
            pageSetup.artistOption.includes(
              getMetafield(product, "artist_name").trim()
            )
          ) {
            return product;
          }
        }
      } else {
        return product;
      }
    });

  const content = filteredProducts
    .sort(dynamicSort(pageSetup.sortOption))
    .map((product, index) => (
      <SingleProduct
        product={product}
        index={index}
        key={`single_product_${index}_${product.id}`}
        isDesktop={isDesktop}
      />
    ));

  return (
    <>
      <PageSeo
        seoTitle={data.shopifyCollection.title}
        seoText={null}
        seoImage={
          data.shopifyCollection.image !== null
            ? data.shopifyCollection.image.src
            : null
        }
      />

      <Page
        color={
          data.shopifyCollection.metafield !== null
            ? data.shopifyCollection.metafield.value
            : `#0b646f`
        }
      >
        {!isDesktop && (
          <section className="breadcrumbs">
            <p className="uppercase">
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>SHOP</Link> →{" "}
              <Link to={`/collections/${data.shopifyCollection.handle}/`}>
                {data.shopifyCollection.title}
              </Link>
            </p>
          </section>
        )}

        <section className="banner-container">
          {data.shopifyCollection.image !== null ? (
            <div className="banner">
              {isDesktop ? (
                <GatsbyImage
                  image={data.shopifyCollection.image.gatsbyImageData}
                  alt={data.shopifyCollection.title}
                  draggable="false"
                  loading="lazy"
                />
              ) : (
                <GatsbyImage
                  image={data.shopifyCollection.mobileImage.gatsbyImageData}
                  alt={data.shopifyCollection.title}
                  draggable="false"
                  loading="lazy"
                />
              )}

              <h1>{data.shopifyCollection.title}</h1>
            </div>
          ) : (
            <div className="banner no-image">
              <AspectRatioImageContainer
                image={null}
                padding={isDesktop ? 35 : 90}
              >
                <h1>{data.shopifyCollection.title}</h1>
              </AspectRatioImageContainer>
            </div>
          )}
        </section>

        {isDesktop && (
          <section className="breadcrumbs">
            <p className="uppercase">
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>SHOP</Link> →{" "}
              <Link to={`/collections/${data.shopifyCollection.handle}/`}>
                {data.shopifyCollection.title}
              </Link>
            </p>
          </section>
        )}

        {content.length >= 1 ? (
          <section className="products-container">{content}</section>
        ) : (
          <section className="empty-products-container">
            <h1>No results</h1>
          </section>
        )}

        {!isDesktop && (
          <MobileFilters
            pageSetup={pageSetup}
            setPageSetup={setPageSetup}
            isDesktop={isDesktop}
            location={location}
          />
        )}
      </Page>
    </>
  );
};

export const query = graphql`
  query CollectionQuery($id: String) {
    shopifyCollection(id: { eq: $id }) {
      title
      handle
      image {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.8)
        src
      }
      mobileImage: image {
        gatsbyImageData(aspectRatio: 1.1)
      }
      metafield(key: "text_color", namespace: "custom") {
        value
      }
      products {
        id
        shopifyId
        featuredImage {
          src
          width
          height
          altText
          gatsbyImageData(width: 700)
        }
        mobileImage: featuredImage {
          gatsbyImageData(width: 300)
        }
        title
        handle
        updatedAt
        totalInventory
        status
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          storefrontId
          inventoryQuantity
          compareAtPrice
          selectedOptions {
            name
            value
          }
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

export default Collection;
